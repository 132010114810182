import { sectionStyle } from 'holded/lib/styles';

export const defaultThemeValues = {
  background: '',
  titleColor: '',
};

export const LogosTheme: LogosThemeType = {
  logos: {
    background: {
      light: sectionStyle.background.light,
      soft: sectionStyle.background.soft,
    },
    titleColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
    },
  },
};

export type LogosThemeType = {
  logos: {
    background: {
      light: string;
      soft: string;
    };
    titleColor: {
      light: string;
      soft: string;
    };
  };
};
