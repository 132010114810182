import { useEffect, useState } from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay, Grid } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ImageMedia } from 'holded/modules/cms/domain/imageMedia';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay, Grid]);

type CarouselProps = {
  data: ImageMedia[] | undefined;
  options?: {
    slidesPerView?: number;
    spaceBetween?: number;
    navigation?: boolean;
    autoplay?: {
      delay: number;
      disableOnInteraction: boolean;
    };
    breakpoints?: {
      [key: string]: {
        slidesPerView: number;
        spaceBetween?: number;
      };
    };
    pagination?: boolean;
  };
};

const Carousel = ({ data, options }: CarouselProps) => {
  const dataCopy = data ? [...data] : [];
  const [mobile, setMobile] = useState(false);
  const [images] = useState([dataCopy.splice(0, dataCopy.length / 2), dataCopy.splice(0, dataCopy.length)]);

  const handlerMobile = () => {
    setMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handlerMobile();
    window.addEventListener('resize', handlerMobile);
  }, []);

  return (
    <div className={'bg-white w-full'}>
      <div className={'sm:px-10'}>
        {mobile ? (
          <>
            {images.map((part, i) => {
              return (
                <Swiper
                  key={i}
                  spaceBetween={options?.spaceBetween}
                  slidesPerView={options?.slidesPerView}
                  navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                  pagination={options?.pagination && mobile ? { dynamicBullets: true } : false}
                  autoplay={options?.autoplay}
                  loop={true}
                  loopedSlides={10}
                  breakpoints={options?.breakpoints}
                  className={'h-32'}
                  centeredSlides={false}
                >
                  {part.map((image) => (
                    <SwiperSlide key={image.id} className={'flex justify-center items-center'}>
                      <NextImage media={image} classImg={'object-cover w-full p-5'} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              );
            })}
          </>
        ) : (
          <Swiper
            spaceBetween={options?.spaceBetween}
            slidesPerView={options?.slidesPerView}
            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
            pagination={options?.pagination && mobile ? { dynamicBullets: true } : false}
            autoplay={options?.autoplay}
            loop={true}
            loopedSlides={10}
            breakpoints={options?.breakpoints}
          >
            {data?.map((image) => (
              <SwiperSlide key={image.id}>
                <div className={'flex items-center justify-center h-32'}>
                  <NextImage media={image} classImg={'object-cover'} width={200} height={100} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {options?.navigation && (
          <div className={'hidden md:flex justify-center mt-10 h-10 w-full'}>
            <div className={'relative w-10 h-full rounded-tl-md rounded-bl-md border-2 border-gray-300 font-bold'}>
              <div className="swiper-button-prev" />
            </div>
            <div
              className={
                'relative w-10 h-full rounded-tr-md rounded-br-md border-2 border-l-0 border-gray-300 font-bold'
              }
            >
              <div className="swiper-button-next" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;
