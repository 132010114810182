import { useEffect, useState } from 'react';

import { getThemeStyles, headingSizeTheme as HEADING_SIZE } from 'holded/lib/styles';
import { getCustomTag, isDefault } from 'holded/lib/utils';
import { Logos1Section } from 'holded/modules/cms/domain/components/logos';
import { defaultThemeValues, LogosTheme } from 'holded/modules/cms/ui/sections/logos/LogosTheme';
import Carousel from 'holded/modules/cms/ui/shared/components/Carousel';

const breakpoints = {
  '100': { slidesPerView: 2, spaceBetween: 0 },
  '800': { slidesPerView: 4, spaceBetween: 20 },
  '1024': { slidesPerView: 5 },
};
const Logos1 = ({ title, titleHref, logos, background, customTag }: Logos1Section) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  const CustomTag = getCustomTag('h1', customTag?.headingTag);
  const defaultHeadingSize = isDefault(customTag?.headingSize);
  const headingSize = `text-h5 md:${HEADING_SIZE[customTag?.headingSize ?? 'default']}`;

  useEffect(() => {
    const themeStyles = getThemeStyles(LogosTheme.logos, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <div className={`${themeValues.background}`}>
      <div className={`max-w-7xl mx-auto pt-12 px-4 sm:px-6 lg:pt-20 lg:px-8 flex flex-col items-center`}>
        <a href={titleHref ?? ''}>
          <CustomTag
            className={`text-center ${defaultHeadingSize ? 'text-h5' : headingSize} font-semibold ${
              themeValues.titleColor
            } cursor-pointer hover:text-blue tracking-wider flex items-center`}
          >
            {title}
            <span aria-hidden="true" className={'ml-2 hidden md:block'}>
              {' '}
              &rarr;
            </span>
          </CustomTag>
        </a>
        <div className={`w-full flex flex-wrap justify-center mt-10`}>
          <Carousel
            data={logos?.data}
            options={{
              spaceBetween: 20,
              slidesPerView: 2,
              breakpoints: breakpoints,
              autoplay: { delay: 2000, disableOnInteraction: false },
              navigation: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Logos1;
